import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/inside_pg_spotlight.css'; 
import {a} from 'react-router-dom';

export default function About_spotlight() {
  return (
    <>
      <div className='inside_spotlight-main'>
        <div className='inside-dv about-dv'>
            
            {/* <div className='overlay-img desk-img'>
                        <img className='overly-img' src={require('../../assets/img/overlay.png')}></img>
            </div> */}
            <div className='overlay-img mbl-img'>
                        <img className='overly-img' src={require('../../assets/img/capricorn_logistics_plane_wing_with_ships_mob.jpg')}></img>
            </div>




            <div className='spot-inner-dv'>
                <div className='spot-cnt'>
                    <h1 className='spot-ttl'>Best-in-Class Global Logistics and Freight Forwarding Solutions.</h1>
                
                    <a href='/services/' className='rd-btn spot-btn'>Our Services</a>
                    <a className='brder-btn spot-btn' href="about#our-beliefs">Explore More</a>
                    <a className='pdf-btn spt-btn' href='http://phpstack-608738-2577092.cloudwaysapps.com/wordpress/wp-content/uploads/2025/01/Corporate-Presentation_20jan.pdf' target="_blank" rel="noopener noreferrer">Company Presentation</a>
                   
                </div>
            </div>
            
        </div>
      </div>
    </>
  )
}
